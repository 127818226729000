import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'
import API from '../../services/api'

// Store
export const indicator = createSlice({
  name: reducerName,
  initialState: {
    responses: [],
    steps: [],
    pdf: null,
    result: null,
    showIntroModalOpen: true,
    loadingUserIndicatorAnwers: false
  },
  reducers: {
    setResponses: (state, { payload }) => {
      state.responses = payload.answers
    },
    appendResponse: (state, { payload }) => {
      const index = state.responses.findIndex(({ question: { id } }) => id === payload.answer.question.id)
      if (index >= 0) {
        state.responses = [...state.responses.slice(0, index), payload.answer, ...state.responses.slice(index + 1)]
      } else {
        state.responses = [...state.responses, payload.answer]
      }
    },
    setNewResponse: (state, { payload }) => {
      const currentResponse = state.responses.findIndex(answer => answer.SK.endsWith(`_${payload.question.id}`))
      if (currentResponse !== -1) { state.responses[currentResponse] = payload } else { state.responses.push(payload) }
    },
    resetResponses: (state) => {
      state.responses = []
    },
    setSteps: (state, { payload }) => {
      state.steps = payload
    },
    setResult: (state, { payload }) => {
      state.result = payload
    },
    setShowIntroModal: (state, { payload }) => {
      state.showIntroModalOpen = payload
    },
    setLoadingUserIndicatorAnwers: (state, { payload }) => {
      state.loadingUserIndicatorAnwers = payload
    }
  }
})

export function loadIndicatorsSteps() {
  return function (dispatch) {
    return new API().get('indicator/steps').then(res => {
      dispatch(indicator.actions.setSteps(res.data))
      return res.data
    })
  }
}

export function saveIndicatorAnswer(body) {
  return function (dispatch) {
    return new API().post('survey/answer', body).then(res => {
      dispatch(indicator.actions.appendResponse(body))
      return res.data
    })
  }
}

export function calculateIndicator(email) {
  return function (dispatch) {
    return new API().get('indicator/calculate', { email, questionnaireType: 'indicator' }).then(res => {
      dispatch(indicator.actions.setResult(res.data))
      return res.data
    })
  }
}

export function loadIndicatorsAnswers(email) {
  return function (dispatch) {
    dispatch(indicator.actions.setLoadingUserIndicatorAnwers(true))
    return new API().get('indicator/answers', { email }).then(res => {
      dispatch(indicator.actions.setResponses(res.data))
      dispatch(indicator.actions.setLoadingUserIndicatorAnwers(false))
      return res.data
    })
  }
}

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setResponses,
  setNewResponse,
  setSteps,
  setResult,
  setShowIntroModal,
  setLoadingUserIndicatorAnwers,
  resetResponses
} = indicator.actions

// Reducer
export default indicator.reducer
