import React, { useMemo } from 'react'
import { Grid, Paper } from '@mui/material'
import Toolpathway, { ToolPathwayExtended } from './combinations/toolpathway'
import Evaluation from './combinations/evaluation'
import Tracker from './combinations/tracker'
import Indicator from './combinations/indicator'
import { useSelector } from 'react-redux'
import { userSlice } from '../../reducers'
import { useEventContext } from '../../contexts/event'
import ResilienceEvaluation from '../resilience-evaluation'
import { AggregationHeader, OrganisationalDriversCard, QuestionnaireIndicatorCard, Empty } from '../../components'
import { useStyles } from './style'
import GenericLoading from './generic-loading'
import { Box } from '@mui/system'

const HomePage = () => {
  const classes = useStyles()
  const user = useSelector(userSlice.selectors.selectUser)
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const events = useEventContext()
  const features = useMemo(() => user?.features
    ? user.features.reduce((acc, feat) => ({ ...acc, [feat.featureId]: true }), {})
    : {},
    [user.features])
  const featureLength = useMemo(() => user?.features?.length || 0, [user.features])
  const isIndividualView = useMemo(() => (selectedView && selectedView.type === 'individual') || !selectedView,
    [selectedView])

  const shouldShowResilienceEvaluation = featureLength === 1 && features.RESILIENCE_EVALUATION
  const shouldShowToolpathway = featureLength === 1 && (features.TOOL_PATHWAYS || features.RESILIENCE_INDICATOR)
  const isEmpty = Object.keys(features).filter(f => features[f]).length === 0
  const isOrganizationalViewEmpty = !(features.RESILIENCE_INDICATOR || features.RESILIENCE_EVALUATION) && !features.RESILIENCE_TRACKER && !features.ORGANISATION_DRIVERS
  const noFrontFeature = isEmpty || (!features.TOOL_PATHWAYS && !features.RESILIENCE_EVALUATION && !features.RESILIENCE_INDICATOR && !features.RESILIENCE_TRACKER)

  if (events.getUser.isWorking) {
    return (
      <Box>
        <GenericLoading />
        <GenericLoading overrideHeight='240px' />
        <GenericLoading overrideHeight='240px' />
        <GenericLoading overrideHeight='400px' />
      </Box>
    )
  }
  if (isIndividualView) {
    return (
      <>
        <Grid container direction='column'>
          {noFrontFeature && <ToolPathwayExtended noFrontFeature={noFrontFeature} />}
          {features.TOOL_PATHWAYS && <Toolpathway />}
          {features.RESILIENCE_EVALUATION && <Evaluation />}
          {features.RESILIENCE_INDICATOR && <Indicator />}
          {features.RESILIENCE_TRACKER && <Tracker />}
          {shouldShowToolpathway && <ToolPathwayExtended />}
          {shouldShowResilienceEvaluation && <ResilienceEvaluation showResults={false} type='resilience' />}
        </Grid>
      </>
    )
  }
  // organizational view
  return (
    <>
      <AggregationHeader title='Management Dashboard' />
      {isOrganizationalViewEmpty &&
        <Paper elevation={2} className={classes.paper}>
          <Empty />
        </Paper>}
      {(features.RESILIENCE_INDICATOR || features.RESILIENCE_EVALUATION) && <QuestionnaireIndicatorCard />}
      {features.RESILIENCE_TRACKER && <Tracker />}
      {features.ORGANISATION_DRIVERS && <OrganisationalDriversCard />}
    </>
  )
}

export default HomePage
